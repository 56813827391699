/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

// Lib UI components
import { Container } from 'react-grid-system';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import FinancialStatemetsTable from '../page-components/FinancialStatements/FinancialStatemetsTable';

// Style
import '../page-styles/FinancialStatemets.scss';
import ForeignDonations from '../page-components/FinancialStatements/ForeignDonations';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function FinancialStatements() {
  /* ********************************** HOOKS ********************************* */

  // Localization

  const { t } = useTranslation();
  const { title } = t('financialStatement', {
    returnObjects: true,
  });

  const { title: donationsTitle } = t('foreignDonations', {
    returnObjects: true,
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Nos Etats Financiers" />
      <Container className="financial-statemets-white-bg" fluid>
        <h1 className="financial-statemets__title">{title}</h1>
        <FinancialStatemetsTable />
        <h1 className="financial-statemets__title">{donationsTitle}</h1>
        <ForeignDonations />
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["FinancialStatements", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default FinancialStatements;
