/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import Button from '../../../shared/UIKit/Button';
// APIs

import { API_UPLOADS, API_FOREIGN_DONATIONS } from '../../../shared/APIs';
// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function ForeignDonations() {
  /* ******************************** HOOKS ******************************* */
  // State
  const [donationData, setDonationData] = useState();
  // Localisation
  const { language } = useI18next();
  const { t } = useTranslation();
  /* ------------------------------ HELPERS --------------------------------- */
  async function getDonations() {
    const { data } = await axios(API_FOREIGN_DONATIONS(language));
    setDonationData(data);
  }
  // Effect
  useEffect(() => {
    getDonations();
  }, []);

  /* ----------------------------- LOCAL VARIABLES ---------------------------- */
  const { tableHeaderCol, buttonText } = t('foreignDonations', {
    returnObjects: true,
  });

  /* ******************************** RENDER HELPERS ******************************* */

  const TableHeaderCols = tableHeaderCol?.map(({ id, title }) => (
    <Col
      key={id}
      order={{ md: 2, xl: 1 }}
      className="donations__table-header-col"
      debug
    >
      {title}
    </Col>
  ));

  const Tablebody = donationData?.data.map(({ id, attributes }) => {
    const { year, status } = attributes;
    return (
      <Row className="donations__table-content" debug>
        <Col
          key={id}
          order={{ md: 2, xl: 1 }}
          className="donations__table-content-col"
          debug
        >
          {year}
        </Col>
        <Col className="donations__table-button" order={{ md: 4, xl: 1 }} debug>
          <Button navigation={API_UPLOADS(status?.data[0]?.attributes?.url)}>
            {buttonText}
          </Button>
        </Col>
      </Row>
    );
  });

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="donations__table">
      <Row className="donations__table-header">{TableHeaderCols}</Row>
      <br />
      {Tablebody}
      <br />
    </Container>
  );
}

ForeignDonations.propTypes = {};

ForeignDonations.defaultProps = {};

export default ForeignDonations;
